<app-header-style-one></app-header-style-one>


<body>
    <div class="page-title-area page-title-style-two item-bg3">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a href="index.html">Home</a></li>
                    <li>Faculties</li>
                </ul>
                <h2>Faculty</h2>
            </div>
        </div>
    </div>
    <div class="container1">
        <h1>TelOne Centre for Learning (TCFL)</h1>
        <div class="faculty">
             <div class="img">
            <img src="/assets/img/telone/img9.jpeg" alt=" ">
         </div>
         <div class="text">
            <ul>
                <li> <p>TCFL offers ICT and soft skills courses to help you succeed in your career. </p> </li>
                <li> <p>With state-of-the-art facilities and skilled staff, affordable tuition fees we will help you launch your future career and meet all your training needs.</p></li>
                <li> <p>So, what are you waiting for?  <span style="color: rgb(243, 24, 24); font-weight: bold;"> Enrol today and start building a better future for yourself!!!</span> </p></li>
                
            </ul>
            
         </div>
        </div>
        <h1 style="margin-top: 40px; ">Programs on offer & entry requirements</h1>
        <h1> Faculty Of Engineering</h1>
        
        <table>
            <tr>
                <th>Engineering Diplomas and Degrees</th>
                <th>Duration</th>
                <th>Tuition fees per semester</th>
            </tr>
            <tr>
                <td>Bachelor of Engineering (Honours) Degree in Telecommunications Engineering</td>
                <td>5 Years</td>
                <td>$600</td>
            </tr>
            <tr>
                <td>Diploma in Telecommunications Engineering(Hexco)</td>
                <td>3 Years</td>
                <td>$500</td>
            </tr>
            <tr>
                <td>Diploma in Software Engineering (Hexco)</td>
                <td>3 Years</td>
                <td>$450</td>
            </tr>
            <tr>
                <td>Diploma in Data Science (Hexco)</td>
                <td>3 Years</td>
                <td>$450</td>
            </tr>
            <tr>
                <td>Diploma in Computer Networking (Hexco)</td>
                <td>3 Years</td>
                <td>$450</td>
            </tr>
            <tr>
                <td>Diploma in Cyber Security (Hexco)</td>
                <td>3 Years</td>
                <td>$450</td>
            </tr>
           
            
        </table>

         <div class="mission">
              <h1 style="font-size: 30px;">Vision</h1>
              <p>To become a specialist ICT university by 2025</p>
              <h1 style="font-size: 30px;">Mission</h1>
              <p>To provide ICT and technology skills for the digital economy in Zimbabwe and beyond.</p>
              <p style="margin-top: 20px; margin-bottom: 40px; color: rgb(243, 24, 24); "><strong>School Of Engineering Contact Details: <br></strong><a href="mailto:tawanda.mapuranga@telone.co.zw" style="color: rgb(40, 149, 216);">tawanda.mapuranga@telone.co.zw</a>
              </p>
              <p style="margin-top: 20px; margin-bottom: 40px; color: rgb(243, 24, 24); "><strong>Short Courses Contact Details: <br></strong><a href="mailto:believe.muchipisi@telone.co.zw" style="color: rgb(40, 149, 216);">believe.muchipisi@telone.co.zw</a>
              </p>
         </div>
        

        <h1>Business School</h1>
        
        <table>
            <tr>
                <th>Business Diploma</th>
                <th>Duration</th>
                <th>Tuition fees per semester</th>
            </tr>
            <tr>
                <td>Diploma in Digital Marketing</td>
                <td>3 Years</td>
                <td>$450</td>
            </tr>
            <tr>
                <td>Diploma in Financial Engineering</td>
                <td>3 Years</td>
                <td>$450</td>
            </tr>

            <tr>
                <td>Diploma in Business Analytics</td>
                <td>3 Years</td>
                <td>$450</td>
            </tr>
            <!-- <tr>
                <td>Postgraduate Diploma in Digital Marketing</td>
                <td>1 Year</td>
                <td>$600</td>
            </tr>
            <tr>
                <td>Postgraduate Diploma in Business Analytics</td>
                <td>1 Year</td>
                <td>$600</td>
            </tr>
            <tr>
                <td>Postgraduate Diploma in Digital Financial Engineering</td>
                <td>1 Year</td>
                <td>$600</td>
            </tr> -->
        </table>

        <div class="mission">
            <h1 style="font-size: 30px;">Vision</h1>
            <p>To become a specialist ICT university by 2025</p>
            <h1 style="font-size: 30px;">Mission</h1>
            <p>To provide ICT and technology skills for the digital economy in Zimbabwe and beyond.</p>
            <p style="margin-top: 20px; margin-bottom: 40px; color: rgb(243, 24, 24); "><strong>School Of Business Contact Details: <br></strong><a href="mailto:tawanda.mapuranga@telone.co.zw" style="color: rgb(40, 149, 216);">tawanda.mapuranga@telone.co.zw</a>
            </p>
            <p style="margin-top: 20px; margin-bottom: 40px; color: rgb(243, 24, 24); "><strong>Short Courses Contact Details: <br></strong><a href="mailto:believe.muschipisi@telone.co.zw" style="color: rgb(40, 149, 216);">believe.muschipisi@telone.co.zw</a>
            </p>
       </div>

        <h1>Entry Requirements for all programs</h1>
        <p><strong>Entry Requirements for Diploma</strong></p>
        <ul>
            <li><p>5 Ordinary level passes including Mathematics, English and Science. (Technical)</p></li>
            <li><p>5 Ordinary level passes including English and Mathematics (Business)</p></li>
            <li><p>You submit your certified copies of Ordinary Level results, National ID and Birth Certificate.</p></li>
        </ul>
        <p><strong>Entry Requirements for Degree</strong></p>
        <ul>
            <li> <p>3 A level passes in Mathematics, Physics and a third science subject.</p></li>
            <li> <p>5 Ordinary level passes including Mathematics, English and Science.</p></li>
            <li><p>You submit your certified copies of Advanced Level results, Ordinary Level results, National ID and Birth Certificate.</p></li>
        </ul>
    </div>
</body>

