<section class="mission-area ptb-100">
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-start">
                <!-- <span class="sub-title">Discover Learning</span> -->
                <!-- <h2>Why TCFL</h2> -->
                <span class="sub-title">At a glance</span>
                 <h2>News and Events</h2>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <!-- <h3>Quality can be better than quantity</h3>
                        <p> We prioritize meaningful contributions for an enhanced user experience. Encouraging valuable, insightful, and accurate information over volume fosters a community of thoughtful discussions and shared expertise. Our emphasis on quality creates a space where users can engage, learn, and collaborate effectively.</p>
                        <p> Quality contributions build individual credibility and enrich the platform's knowledge. We appreciate users who offer well-reasoned responses, share expertise, and uphold a high content standard. Together, we prioritize quality interactions over quantity, creating an environment of excellence. Thank you for being part of this commitment! </p> -->
                        <h3><a>Upcoming Events</a></h3>
                        <p>•Semester Commencement 19 August 2024</p>
                        <p>•Registration Commencement 22 August 2024 </p>
                        <p>•Orientation 14-16 August 2024</p>
                    </ng-template>
                    <ng-template carouselSlide>
                        <!-- <h3>A place where you can achieve</h3>
                        <p>Discover a space where learning transcends boundaries! Our center is a haven for those seeking knowledge and growth. Achieve your academic and personal goals with expert guidance and innovative resources.</p>
                        <p> Join a community dedicated to fostering a love for learning, where every individual journey is valued. Welcome to a place where education becomes a transformative experience!</p> -->
                        <h3><a >Harare Agricultural Show</a></h3>
                        <p>The Harare Agricultural Show is an important exhibition platform where REF is reaching out to
                             many clients in and outside Harare. The idea to exhibit is to allow people who live in rural Zimbabwe to take up modern energy services like grid electricity, solar and biogas energy to improve their way of life.
                            </p>
                    </ng-template>
                    <ng-template carouselSlide>
                        <!-- <h3>A place where you can achieve</h3>
                        <p>Discover a space where learning transcends boundaries! Our center is a haven for those seeking knowledge and growth. Achieve your academic and personal goals with expert guidance and innovative resources.</p>
                        <p> Join a community dedicated to fostering a love for learning, where every individual journey is valued. Welcome to a place where education becomes a transformative experience!</p> -->
                        <h3><a >Huawei seeds for the future</a></h3>
                        <p>TCFL students shines at Huawei Seeds for the Future
                            We are pleased to announce that 7 of our students were selected to participate at the Huawei Seeds for the Future. At platform that allows young innovators to pitch ideas which are then incubated by Huawei. Keep Shining TCFL!</p>

                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>