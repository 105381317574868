<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg12">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Events</li>
            </ul>
            <h2>News And Events</h2>
        </div>
    </div>
</div>

<div class="events-area pt-100 pb-70">
    <div class="container">
   
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg9">
                        <img src="assets/img/telone/china.jpg" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <p></p>
                        <p></p><p></p>
                        <h3><a>TCFL Student Shines In China</a></h3>
                        <p>Our student Chakwizira Diza was in China for a presentation on Huawei and the TelOne Center for Learning where the Honorable President of Zimbabwe and Minister of ICT, Postal and Courier Services were present.
                        </p>
                        <p></p>
                        <p></p><p>
                       
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>1</span>
                                <h3>3 September 2024</h3>
                                <!-- <p>10:30 PM</p> -->
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg5">
                        <img src="assets/img/telone/where-you-study-matters.jpg" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3><a>Upcoming Events</a></h3>
                        <p>•Semester Commencement 19 August 2024</p>
                        <p>•Registration Commencement 22 August 2024 </p>
                        <p>•Orientation 14-16 August 2024</p>
                       
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>2</span>
                                <h3></h3>
                                <!-- <p>10:30 PM</p> -->
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg6">
                        <img src="assets/img/telone/show1.jpg" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3><a >Harare Agricultural Show</a></h3>
                        <p>The Harare Agricultural Show is an important exhibition platform where REF is reaching out to
                             many clients in and outside Harare. The idea to exhibit is to allow people who live in rural Zimbabwe to take up modern energy services like grid electricity, solar and biogas energy to improve their way of life.
                            </p>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>3</span>
                                <h3>End of August</h3>
                                <!-- <p>10:30 PM</p> -->
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg2">
                        <img src="assets/img/telone/student.jpg" alt="image">
                    </div>
                </div>
                <!-- <div class="events-content">
                    <div class="content">
                        <h3><a >Introduction of smart Wi-Fi </a></h3>
                        <p>TCFL has launched a new campus wide WIFI network for students. The new Wi-Fi system utilises vouchers. These vouchers are issued to students upon registration. </p>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>03</span>
                                <h3>24 October</h3>
                                <p>8:30 PM</p>
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg3">
                        <img src="assets\img\telone\ideas.jpg" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <!-- <h3><a >Huawei seeds for the future</a></h3> -->
                        <h3><a>TCFL students at Huawei Seeds for the Future</a></h3>
                           <p>We are pleased to announce that 7 of our students were selected to participate at the Huawei Seeds for the Future. At platform that allows young innovators to pitch ideas which are then incubated by Huawei. Keep Shining TCFL!</p>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>4</span>
                                <h3>25 October</h3>
                                <!-- <p>11:30 PM</p> -->
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg4">
                        <img src="assets/img/telone/1920X1080-------------------.jpg" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3><a >New SCR structure in place</a></h3>
                        <p>TelOne Centre for Learning elected its first student council representatives. The move was welcome by both students and TCFL lecturers and Admin staff as it will go a long way in bridging a gap between the two parties and ensuring that all students needs are adequately meet and addressed
                            (Pic of SCR team )
                            </p>
                       
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                        <!-- <span class="location"><i class='bx bx-map'></i> 120 G-35 Spinsovila Sc, USA</span>
                        <a routerLink="/single-events" class="join-now-btn">Join Now</a> -->
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>5</span>
                                <h3>26 October</h3>
                                <!-- <p>10:30 PM</p> -->
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg7">
                        <img src="assets/img/telone/learning2.jpg" alt="image">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3><a >TCFL Graduation</a></h3>
                        <p>Join us in celebrating the remarkable achievements of our graduates. This momentous occasion marks the culmination of years of dedication, 
                            hard work, and perseverance. Together, let us honor the accomplishments of our graduates as they embark on a new chapter filled with promise and opportunity.
                            </p>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>6</span>
                                <h3>TBA (Proposed 15 November 2024)</h3>
                                <!-- <p>10:30 PM</p> -->
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    </div>
</div>